<template>
  <div>
    <v-card-title class="pb-0 green darken-4">
      <p class="body-1 font-weight-medium">
        Payment Type
      </p>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="ml-2 pt-0">
      <v-radio-group
        v-model="selectedPaymentType"
        row
        @change="onPaymentTypeChange"
      >
        <v-radio
          label="All Payments"
          :value="1"
        ></v-radio>
        <v-radio
          label="Internet Payments"
          :value="2"
        ></v-radio>
        <v-radio
          label="Installation Fee Payments"
          :value="3"
        ></v-radio>
      </v-radio-group>
    </v-card-text>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedPaymentType: 1,
      filterLabel: 'all payments',
    }
  },
  methods: {
    onPaymentTypeChange() {
      this.updateFilterLabel()
      this.$emit('payment-type-change', {
        value: this.selectedPaymentType,
        label: this.filterLabel,
      })
    },
    updateFilterLabel() {
      switch (this.selectedPaymentType) {
        case 1:
          this.filterLabel = 'all payments'
          break
        case 2:
          this.filterLabel = 'internet payments'
          break
        case 3:
          this.filterLabel = 'installation fee payments'
          break
        default:
          this.filterLabel = 'all payments'
      }
    },
  },
}
</script>
